import {
	getPayment,
	getPassenger,
	getConfirmation,
} from '../services/PrebookingServiceV2';
/*para usar data hardcode EJ: ?demo=true&module=flights*/
const windowUrl = window.location.search;
const demo = new URLSearchParams(windowUrl).get('demo');

export const getInfoStepAction = async (
	ipv4,
	hash,
	nextStepAction,
	addToCart,
	currentDataStep,
	prevStep = null
) => {
	let response = [];
	let action = '';
	let form = new Object();
	console.log('nextStepAction', nextStepAction);
	console.log('currentDataStep', currentDataStep);
	console.log('prevActiveStep', prevStep);

	// Si el paso tiene un V2 al final del nombre, se lo quita.
	let prevStepName = prevStep != null ? prevStep.stepName.replace('V2', '') : null;
	// Armo request a enviar
	let request = {
		hash: hash,
		ip: ipv4,
		step: prevStepName,
		finishedStep: currentDataStep,
	};
	console.log('request a enviar a apicheckout', request);

	switch (nextStepAction) {
		// Step de Pago
		case 'getPayment':
			action = 'GET_PAYMENT_V2'; // Action que conecta con el reducer.

			if (demo === 'true') {
				const module = new URLSearchParams(windowUrl).get('module');
				response = await import(
					//`.././demo-request/${module}/getPaymentAhora12.json`
					`.././demo-request/${module}/getPayment.json`
				);
				response = response.default;
				console.log('VERSION HARDCODE LOCAL');
			} else {
				console.log('Consulta service getPayment');
				response = await getPayment(request);
			}

			break;
		// Step de Pasajeros
		case 'getPassenger':
			action = 'GET_PASSENGER_V2'; // Action que conecta con el reducer.

			if (demo === 'true') {
				const module = new URLSearchParams(windowUrl).get('module');
				response = await import(`.././demo-request/${module}/getPassenger.json`);
				response = response.default;
				console.log('VERSION HARDCODE LOCAL');
			} else {
				console.log('Consulta service getPassenger');
				response = await getPassenger(request);
			}
			break;
		// Step de Confirmación
		case 'showInfo':
			action = 'SHOW_INFO_V2';
			//  console.log(JSON.stringify(requestConfirmation))
			if (demo === 'true') {
				const module = new URLSearchParams(windowUrl).get('module');
				response = await import(
					`.././demo-request/${module}/getConfirmation.json`
				);
				response = response.default;
				console.log('VERSION HARDCODE LOCAL');
			} else {
				response = await getConfirmation(request);
			}

			break;
		// En desuso para flujo actual de V2
		case 'sendPassenger':
			action = 'SEND_PASSENGER';
			response = currentDataStep;
			break;
		default:
			action = 'GET_UPDATE_INFO';
			break;
	}

	console.log("STEPACTIONV2 addToCart", addToCart)

	return {
		type: action,
		payload: response,
		addToCart: addToCart,
	};
};

export const addCrossellAction = async (slider, cardProduct, purchase) => {
	console.log("purchase en action", purchase)
	var index = cardProduct.idOffer!==undefined ? parseInt(cardProduct.idOffer) : 0

	let totalWithFormat = parseInt(purchase.totalAmountReal)
	if (cardProduct.module=="brandedFares"){
		// Si el crossell es de brandedFares, se indicará que el monto a sumar en el desglose 
		// es la diferencia con respecto al precio base, y no el amount normal del priceDetail.
		totalWithFormat+=parseInt(cardProduct.priceDetail.priceDiffReal);
		cardProduct.priceDetail.amountReal = cardProduct.priceDetail.priceDiffReal;
		cardProduct.priceDetail.amount = cardProduct.priceDetail.priceDiff;
	}else{
		totalWithFormat+=parseInt(cardProduct.priceDetail.amountReal);
	}
	let total = totalWithFormat.toLocaleString();

	purchase.totalAmount = total.replaceAll(',', '.');
	purchase.totalAmountReal = totalWithFormat;
	purchase.priceDetail.push(cardProduct.priceDetail);
	console.log("purchase en action 2", purchase)
	let newAddToCart = {}
	newAddToCart["priceDetailProduct"] =  purchase.priceDetail.filter(
		(element) => element.addToCart === true
	);
	newAddToCart["priceDetailCards"] =  []  

	return {
		type: 'ADD_CROSSELL_TO_CART_V2',
		payload: newAddToCart,
		purchase: purchase,
		cardProduct: cardProduct,
		indexSelected: index,
		slider: slider,
	};
};

export const removeCrossellAction = async (cardProduct, purchase) => {
	let purchaseModificado = purchase.priceDetail.filter(
		(element) => element !== cardProduct.priceDetail
	);
	purchase.priceDetail = purchaseModificado;

	let totalWithFormat =
		parseInt(purchase.totalAmountReal) -
		parseInt(cardProduct.priceDetail.amountReal);
	let total = totalWithFormat.toLocaleString();
	purchase.totalAmount = total.replace(',', '.');
	purchase.totalAmountReal = totalWithFormat;

	let newAddToCart = {}
	newAddToCart["priceDetailProduct"] =  purchase.priceDetail.filter(
		(element) => element.addToCart === true
	);
	newAddToCart["priceDetailCards"] =  []  

	return {
		type: 'REMOVE_CROSSELL_TO_CART_V2',
		payload: newAddToCart,
		purchase: purchase,
		cardProduct: cardProduct,
	};
};

export const updateAction = async ( bank, creditCard, paymentFee, allowFormSelected) => {
	console.log("paymentFee", paymentFee)

	return {
		type: 'UPDATE_PURCHASE_V2',
		bank: bank,
		creditCard: creditCard,
		paymentFee: paymentFee,
		allowFormSelected: allowFormSelected,
	};
};

export const setPurchaseAction = async ( initialPurchase ) => {
	console.log("Entra en action setInitialPurchaseAction");

	return {
		type: 'SET_PURCHASE_V2',
		payload: null,
		purchase:  initialPurchase
	};
};

export const addQuoteAction = async (quote, indexCard) => {
	// Al agregar una cuota, no se actualizan los priceDetailProduct. 
	// En el stepReducerV2 solo se actualizará priceDetailCards con la nueva quote enviada.
	console.log("[PURCHASE] Ejecutando ADD quote ACTION",quote)
	let purchase = quote.purchaseDetail

	let price = purchase.priceDetail.filter(
		(element) => element.addToCart === false
	);
	let newCard = {
		"installmentAmount" : quote.purchaseDetail.installmentAmount,
		"totalAmount" : quote.purchaseDetail.totalAmount,
		"coef" : quote.coef,
		"payment_fees" : quote.payment_fees,
		"unify_coef" : quote.unify_coef,
		"bonificacion" : quote.bonificacion,
		"installment_quota_initial_cantidad" : quote.installment_quota_initial_cantidad,
		"installment_quota_initial_value" : quote.installment_quota_initial_value,
		"installment_quota_final_cantidad" : quote.installment_quota_final_cantidad,
		"installment_quota_final_value" : quote.installment_quota_final_value,
		"cardName" : quote.credit_card_name,
		"cardType" : (quote.credit_card_type) ? quote.credit_card_type : null,
		"indexCard" : indexCard,
		"price" : price
	}

	//currentAddToCart["priceDetailCards"].push(newCard)
	let newValues= {
		"newCard" : newCard,
		//"priceDetailProduct" : newPriceDetailProduct
	}

	return {
		type: 'ADD_QUOTE_TO_CART_V2',
		payload: newValues,
		purchase: purchase,
	};
};


export const deleteQuoteAction = async (quote, indexCard) => {
	//console.log("[PURCHASE] Ejecutando delete quote ACTION", quote);
	console.log("[PURCHASE] index tarjeta de nuva quote", indexCard);

	return {
		type: 'DELETE_QUOTE_FROM_CART_V2',
		payload: indexCard,
		purchase: quote!==null ? quote.purchaseDetail : null
	};
};

export const updateGeneralPurchaseAction = async ( newPurchase) => {
	return {
		type: 'UPDATE_GENERAL_PURCHASE_V2',
		purchase: newPurchase,
	};
};

export const restartPaymentAction = () => {
	return {
		type: 'RESTART_PAYMENT_V2',
	};
};

export const updatePreloadAction = (boolean) => {
	return {
		type: 'UPDATE_PRELOAD_V2',
		preload: boolean,
	};
};

export const updatePreloadRetryAction = (boolean) => {
	return {
		type: 'UPDATE_PRELOAD_V2',
		preload: boolean,
	};
};
