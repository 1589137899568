import React, {Fragment} from 'react'
import {Grid, Typography, Icon } from '@material-ui/core';

export default function CardV4(props) {
    const {template, item, detailProduct, showDetailSearch, showDetailProductFull, detail_button} = props

    console.log('CardV4', props)

    
    return (
        <div className={template}>

            {item.title && <Typography className="detail_item_title mt-1" variant="h4">{item.title}</Typography>}

            <ul className="detail_item_list products">                                  
                {detailProduct.map((item, i) => 
                    <li key={i} className="list_item">
                        <Typography component="p" className={`${item.className} ${item.icon ? 'has_icon' : ''}`}>
                           {item.icon && <Icon className={item.icon} >{item.icon}</Icon>} {item.title && 
                           <span className={`${item.className.includes('strong-title') ? 'strong' : ''}`
                           }>{item.title}</span>} {item.description}
                       </Typography>
                            
                    </li>
                )} 
            </ul>



           
        </div>
    )
}
