const INITIAL_STATE = {
  dataStep: { "SEND_CROSSELL": null, "GET_PASSENGER":null ,"SEND_PASSENGER": null, "SHOW_INFO": null, "GET_UPDATE_INFO": null, "GET_PAYMENT": null },
  preload: true,
  addToCart: null,
  historyAddToCart: '',
  selected: {},
  indexCrossellSelected: {},
  selectedData: {},
  purchase: null,
  bank: {},
  creditCard: {},
  paymentFee: {},
  originalTotal: 0,
  allowFormMethodPayment: [],
  historyMethodPayment: [],
  preloadRetry: true,
  paymentSelected: null,
  paymentMethodPurchases : [],
  modalShow : {"assistCrossell": false,"brandedFaresCrossell":true},
  //  purchase  y addToCart base, los productos agregados sin tener en cuenta metodos de pago.
  purchaseBase: null,   
  addToCartBase: null,    
  renderPayments: true
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case "GET_PAYMENT_V2":
      let newAddToCartBase = {...state.addToCart}
      let newPurchaseBase = {...state.purchase}
      console.log("LLego respuesta de initStepPyamentV2", action.payload);    
      if(action.payload.error && action.payload.error == true){
        return { ...state, renderPayments: true, preload: true,  preloadRetry:false, historyAddToCart: [], historyMethodPayment: [], allowFormMethodPayment: [], dataStep: { "GET_PAYMENT": action.payload } };
      }

      if(action.payload.errorDecompress && action.payload.errorDecompress == true){
        return { ...state, renderPayments: true, preload: false}
      }

      const allow = [];
      var purchasesAux = [];
      var currentPurchase = state.purchase;
      var newCP = {};
      console.log("purchase que se tomara como base",currentPurchase);
      action.payload.response.step.data.payments.map((payment, i) => {
        newCP = {}
        allow[i] = { "name": payment.name, "allow": false, "allowBlock":true };

        // Logica para agregar a cada metodo de pago un determinado purchaseDetail, 
        // con base en el actual, mas los nuevos cargos calculados desde el back, si hubieran. (hecho para el transactionCost)
        console.log("procesando opcion de pago con id", i);
        if (currentPurchase!==null){
          if (payment["aditionalCharges"] !== undefined && payment["aditionalCharges"] !== null){
            // Si posee cargos adicionales, los sumará a los cargos del pivot (que es el primer elemento del priceDetail)
            let purchaseWithNewCharges = {...currentPurchase};

            if (purchaseWithNewCharges.priceDetail.length>0){
              purchaseWithNewCharges.priceDetail = [...purchaseWithNewCharges.priceDetail];
              let priceDetail = [...purchaseWithNewCharges.priceDetail];
              let pivotPriceDetail = {...priceDetail[0]};
              let chargePivot = [...pivotPriceDetail.charge];
              let sumNewCharges = 0;

              if (pivotPriceDetail.charge.length>0){
                  
                payment["aditionalCharges"]["newCharges"].forEach(function(newCharge){
                  //agrega nuevo cargo
                  console.log("agrega nuevo cargo a charge de pivot", newCharge);
                  chargePivot.push(newCharge);
                  // TODO: Si tiene un valor != null en newCharge.sumToCharge, en lugar de agregar un cargo, se suma a uno ya existente.
                  sumNewCharges += newCharge.valorReal;
                })
              }
              console.log("sumNewCharges",sumNewCharges);
              pivotPriceDetail.charge = chargePivot;
              // actualiza totales a nivel de priceDetail
              pivotPriceDetail.amountReal+= sumNewCharges;
              // convierte a string el nuevo total           
              console.log("[TEST PURCHASE] valor original", pivotPriceDetail.amount);
              let newTotalPriceDetail = parseFloat(pivotPriceDetail.amount.replaceAll('.', ''));
              console.log("[TEST PURCHASE] newTotalPriceDetail REPLACE", pivotPriceDetail.amount.replaceAll('.', ''));
              console.log("[TEST PURCHASE] newTotalPriceDetail parseFloaT", parseFloat(pivotPriceDetail.amount.replaceAll('.', '')));
              
              newTotalPriceDetail+=sumNewCharges;
              newTotalPriceDetail = Math.round(newTotalPriceDetail);  // quito decimales
              console.log("[TEST PURCHASE] newTotalPriceDetail round()", newTotalPriceDetail);
              let totalStr = newTotalPriceDetail.toLocaleString();
              console.log("[TEST PURCHASE] newTotalPriceDetail toLocaleSting", newTotalPriceDetail.toLocaleString());
              pivotPriceDetail.amount = totalStr.replaceAll(",",".");
              console.log("[TEST PURCHASE] newTotalPriceDetail FINAL",  pivotPriceDetail.amount);
              console.log("pivotPriceDetail",pivotPriceDetail);
              purchaseWithNewCharges.priceDetail[0] = pivotPriceDetail;

              // actualiza totales a nivel de purchase
              purchaseWithNewCharges.totalAmountReal += sumNewCharges; 
              // convierte a string el nuevo total
              let newTotalPurchase = purchaseWithNewCharges.totalAmount.replaceAll('.', '');
              newTotalPurchase = parseFloat(newTotalPurchase);
              newTotalPurchase+=sumNewCharges;
              newTotalPurchase = Math.round(newTotalPurchase);
              let totalPurchaseStr = newTotalPurchase.toLocaleString();
              purchaseWithNewCharges.totalAmount = totalPurchaseStr.replaceAll(",",".");
              
              console.log("Tiene cargos adicionales, purchase nuevo", purchaseWithNewCharges);
              purchasesAux[i] = purchaseWithNewCharges;
            } 
          }else{
            newCP = {...currentPurchase};
            console.log("No tiene cargos, purchase por default",i);
            purchasesAux[i] = newCP;
          }
        }   // Fin logica nueva de cargos.
        
      })
      console.log("purchaseAux hasta ahora: ", purchasesAux);
      console.log("STEPREDUCERV2 addToCart", action.addToCart)
      console.log("STEPREDUCERV2 paymentMethodPurchases",purchasesAux)
      return { ...state, renderPayments: true, preload: true,  preloadRetry:false, paymentMethodPurchases:purchasesAux, historyAddToCart: action.addToCart, historyMethodPayment: allow, allowFormMethodPayment: allow, dataStep: { "GET_PAYMENT": action.payload }, addToCart: action.addToCart, addToCartBase:newAddToCartBase, purchaseBase: newPurchaseBase};
    case "GET_PASSENGER_V2":
        console.log("LLego respuesta de initStepPassengerV2", action.payload);    
        if(action.payload.error && action.payload.error == true){
          return { ...state, renderPayments: true, preload: false,  preloadRetry:false, historyAddToCart: [], dataStep: { "GET_PASSENGER": action.payload }, addToCart: [] };
        }
        return { ...state, renderPayments: true, preload: true,  preloadRetry:false, historyAddToCart: action.addToCart, dataStep: { "GET_PASSENGER": action.payload }, addToCart: action.addToCart };
     
    case "GET_UPDATE_INFO":

      return { ...state, renderPayments: true, historyAddToCart: action.addToCart, dataStep: { "GET_UPDATE_INFO": action.payload }, addToCart: action.addToCart };
    case "ADD_CROSSELL_TO_CART_V2":
    
     let nameCrossellSelected = `${action.cardProduct.module}Crossell`;
     
     console.log('nameCrossellSelected', `${action.cardProduct.module}Crossell`)
     console.log('nameCrossellSelected action', action)
    // busca el modulo crossell que agrega al carrito y lo pone en false, para que oculte el slider y muestre el resumen con form
      let response = Object.keys(action.slider).filter(element => element === nameCrossellSelected);
      // guarda solo el indice del item seleccionado.
      var indexSelectedDict = state.indexCrossellSelected;
      indexSelectedDict[action.cardProduct.module] = action.indexSelected;
      // guarda la info del item seleccionado
      action.slider[response].status = false;
      action.slider[response].resumen = action.cardProduct;
      
      var newModalShowAdd = state.modalShow;
      if (nameCrossellSelected == "brandedFaresCrossell"){
        newModalShowAdd["brandedFaresCrossell"] = true;
      }else{
        newModalShowAdd["brandedFaresCrossell"] = false;
      }

      

      console.log("purchase en ADD_CROSSELL_TO_CART_V2", action.purchase)
      console.log('indexSelectedDict', indexSelectedDict)
      return { ...state, modalShow: newModalShowAdd, renderPayments: true,historyAddToCart: action.payload, addToCart: action.payload, purchase: action.purchase, selected: action.slider, indexCrossellSelected: indexSelectedDict, selectedData: action.cardProduct };
    case "REMOVE_CROSSELL_TO_CART_V2":
      
      let nameCrossellRemoved = `${action.cardProduct.module}Crossell`;
    // busco el modulo crossell que uito del carrito y lo pongo en true, para que vuelva a mostrar el slider
      let responseFilter = Object.keys(state.selected).filter(element => element === nameCrossellRemoved);
      state.selected[responseFilter].status = true;
      state.selected[responseFilter].resumen = {};

      var newModalShowRemove = state.modalShow;
      if (nameCrossellRemoved == "brandedFaresCrossell"){
        newModalShowRemove["brandedFaresCrossell"] = true;
      }else{
        newModalShowRemove["brandedFaresCrossell"] = false;
      }
      return { ...state, modalShow: newModalShowRemove, renderPayments: true, addToCart: action.payload, purchase: action.purchase, selected: state.selected, selectedData: [] };
    
    case "ADD_QUOTE_TO_CART_V2":        
        console.log("[PURCHASE ADD] ADD_QUOTE_TO_CART_V2", action.payload)
        let newPriceDetailCards = (state.addToCart["priceDetailCards"] != undefined) ? state.addToCart["priceDetailCards"] : []
        
        let filterArr = newPriceDetailCards.filter(card => card["indexCard"] == action.payload["newCard"]["indexCard"]);
        if (filterArr.length==0){
          console.log("[PURCHASE ADD] Inserto nueva card quote")
          newPriceDetailCards.push(action.payload["newCard"])
        }else{
          console.log("[PURCHASE ADD] reemplazo card quote")
          newPriceDetailCards[action.payload["newCard"]["indexCard"]] = action.payload["newCard"]
        }

        let newAddToCart = {
          "priceDetailProduct" : (state.addToCart["priceDetailProduct"] != undefined) ? state.addToCart["priceDetailProduct"] : [],
          "priceDetailCards" : newPriceDetailCards
        }

        //console.log("[PURCHASE ADD] new priceDetailCards",newAddToCart["priceDetailCards"])
        let totalFloat= 0
        newAddToCart["priceDetailCards"].forEach(function(priceDetail){
          //console.log("SUMARA AL PURCHASE >", priceDetail.totalAmount)
          // quita los puntos de miles, ya que los float de JS no llevan.
          let totalToSum = priceDetail.totalAmount.replaceAll('.', ''); 
          // no reemplaza decimales ya que no tiene debido a que el totalAmount está formateado para el usuario, se lo quitan en el back.
          //totalToSum = totalToSum.replace(",",'.')  
          totalFloat+=parseFloat(totalToSum);  // ahora se puede convertir en float.
          //console.log("EN FLOAT ES ", parseFloat(totalToSum));
        })

        //console.log("[PURCHASE ADD] NUEVO TOTAL AMOUNT PURCHASE >",totalFloat )
        let total = totalFloat.toLocaleString();

        let newPurchase = action.purchase
        newPurchase.totalAmount = total.replaceAll(',', '.');
        //newPurchase.totalAmountReal = totalFloat; // NO se usa el amount Real
        console.log("[PURCHASE ADD] purchase en ADD_QUOTE_TO_CART_V2", newPurchase)
        return { ...state, renderPayments: false, historyAddToCart: newAddToCart, addToCart: newAddToCart, purchase: newPurchase };
       
    case "DELETE_QUOTE_FROM_CART_V2":  
        console.log("[PURCHASE DEL] DELETE QUOTE FROM CART");
        let newPriceDetailCardsDel = (state.addToCart["priceDetailCards"] != undefined) ? state.addToCart["priceDetailCards"] : []
        //console.log("[PURCHASE DEL] newPriceDetailCardsDel", newPriceDetailCardsDel.length)

        let auxNew = []
        newPriceDetailCardsDel.forEach(function(priceDetail){
          // creo un nuevo array actualizado sin la quote con el indexCard a eliminar
          if (priceDetail["indexCard"]!==action.payload){
            auxNew.push(priceDetail);
          }
        })
        if (newPriceDetailCardsDel!== auxNew){
            newPriceDetailCardsDel = auxNew;
            let newAddToCartDel = {
              "priceDetailProduct" : (state.addToCart["priceDetailProduct"] != undefined) ? state.addToCart["priceDetailProduct"] : [],
              "priceDetailCards" : newPriceDetailCardsDel
            }
    
            //console.log("[PURCHASE DEL] new priceDetailCards",newAddToCartDel["priceDetailCards"])
            let totalFloatDel= 0
            newAddToCartDel["priceDetailCards"].forEach(function(priceDetail){
              let totalToSum = priceDetail.totalAmount.replaceAll('.', ''); 
              totalFloatDel+=parseFloat(totalToSum); 
            })
    
            //console.log("[PURCHASE DEL] NUEVO TOTAL AMOUNT PURCHASE >",totalFloatDel )
            // Si la suma dió 0, es porque se eliminaron todas las quotes, entonces vuelvo al total original.
            if (totalFloatDel==0){
              totalFloatDel = state.originalTotal
            }
            let totalDel = totalFloatDel.toLocaleString();
    
            let newPurchaseDel = state.purchase 
            if (action.purchase !==null){
              newPurchaseDel = action.purchase
              newPurchaseDel.totalAmount = totalDel.replaceAll(',', '.');
              //newPurchase.totalAmountReal = totalFloatDel; // NO se usa el amount Real
              console.log("[PURCHASE DEL] purchase en DELETE_QUOTE_FROM_CART_V2", newPurchaseDel)
            }
            return { ...state, renderPayments: false, historyAddToCart: newAddToCartDel, addToCart: newAddToCartDel, purchase: newPurchaseDel };
    
        }else{
          console.log("[PURCHASE DEL] no hay elementos para el index indicado", newPriceDetailCardsDel)
          return { ...state, renderPayments: false}
        }
    case "SET_PURCHASE_V2":
      console.log("seteando purchase inicial en reducer", action.purchase);
      return { ...state, renderPayments: true, purchase: action.purchase}
    case "UPDATE_PURCHASE_V2":
      /* Esta opcion se ejecuta cuando el usuario selecciona un pago */
      console.log("GET_PAYMENT en UPDATE_PURCHASE_V2", state.dataStep["GET_PAYMENT"])
      console.log("action", action)
      const updateAllowForm = [];
     
      for (let i = 0; i < state.allowFormMethodPayment.length; i++) {
        if (i === action.allowFormSelected.index) {
          updateAllowForm[i] = { "name": state.allowFormMethodPayment[i].name, "allow": true, "allowBlock":false };
        } else {
          updateAllowForm[i] = { "name": state.allowFormMethodPayment[i].name, "allow": false , "allowblock": false};
        }
      }
      //console.log(action.allowFormSelected.name)
      
      // ACTUALIZA PURCHASE
      let purchaseDetail = null;
      if (state.paymentMethodPurchases[action.allowFormSelected.index] !== undefined){
        purchaseDetail = state.paymentMethodPurchases[action.allowFormSelected.index]
        console.log("state.paymentMethodPurchase en pago seleccionado",state.paymentMethodPurchases);
      }
      //Logica vieja, toma el dato del purchase ya existente en los componentes.
      if (purchaseDetail==null){
        console.log("purchaseDetail es null, logica por defecto.");
        purchaseDetail = action.paymentFee.purchaseDetail;
        if(action.paymentFee.purchaseDetail.purchaseDetail){
          purchaseDetail = action.paymentFee.purchaseDetail.purchaseDetail;
        }
      }

      //ACTUALIZA ADD TO CART
      let addToCartUpdated = {
        "priceDetailProduct" : purchaseDetail.priceDetail.filter((element) => element.addToCart === true),
        "priceDetailCards" : [] // Es vacío porque a esta altura todavía no se eligió ninguna tarjeta, solo el metodo de pago.
      };

      console.log("nuevo addToCart", addToCartUpdated);
      console.log("nuevo purchase detail en UPDATE_PURCHASE_V2", purchaseDetail)
      return { ...state, renderPayments: true, paymentSelected: action.allowFormSelected.index, addToCart: addToCartUpdated, purchase: purchaseDetail,  originalTotal: purchaseDetail.totalAmount, allowFormMethodPayment: updateAllowForm, bank: action.bank, creditCard: action.creditCard, paymentFee: action.paymentFee };
    
    case "UPDATE_GENERAL_PURCHASE_V2":
      /* Esta accion se ejecuta cuando se modifica un pago */
      console.log("actualiza purchase", action.purchase);
      return { ...state, renderPayments: false, purchase : action.purchase};
       
    case "RESTART_PAYMENT_V2":
      /* Esta accion se ejecuta cuando se modifica un pago */
      console.log("RESTART PURCHASE purchaseBase", state.purchaseBase);
      console.log("RESTART PURCHASE addToCartBase", state.addToCartBase);

      return { ...state, renderPayments: true, paymentFee: [], purchase: {...state.purchaseBase}, addToCart: {...state.addToCartBase}, paymentSelected:null, allowFormMethodPayment: state.historyMethodPayment};
    case "SHOW_INFO_V2":
      console.log("LLego respuesta de initStepConfirmationV2", action.payload);    
      if(action.payload.error && action.payload.error == true){
        return { ...state, renderPayments: true, preload: false, dataStep: { "SHOW_INFO": action.payload } };
      }
      return { ...state, renderPayments: true, preload: true, dataStep: { "SHOW_INFO": action.payload } }
    case "SEND_PASSENGER":
      return { ...state, renderPayments: true, preload: true, historyAddToCart: action.addToCart, dataStep: { "SEND_PASSENGER": action.payload }, addToCart: action.addToCart };
    case "SEND_CROSSELL":
        console.log("Entro a reducer case SEND_CROSSELL")
      return { ...state, renderPayments: true, preload: true, historyAddToCart: action.addToCart, dataStep: { "SEND_CROSSELL": action.payload }, addToCart: action.addToCart };
   
    case "UPDATE_PRELOAD_V2":
      return { ...state, renderPayments: true, addToCart: state.addToCart, preload: false  }
    case "UPDATE_PRELOAD_RETRY":
    
      return { ...state, renderPayments: true, addToCart: state.addToCart, preloadRetry:true  }
    default:
      return state;
  }
};
