import axios from "axios";
import config from "../config/config.json"
import {getClient} from "./Authentication";
import dataInitSteps from "../demo-request/hotels/getPrebooking.json";

const endpointProd = 'https://pma2wz3buc.execute-api.us-east-1.amazonaws.com/prod-bna';
const endpoint_stg = 'https://1icm5bl099.execute-api.us-east-1.amazonaws.com/stg';
const endpoint_dev = 'https://y43e3jv1u1.execute-api.us-east-1.amazonaws.com/dev';
const endpoint_dev_assists = 'https://3f6e30e743.execute-api.us-east-1.amazonaws.com/dev-assists';
const endpoint_prod = 'https://qfxt620jf7.execute-api.us-east-1.amazonaws.com/prod';
const endpoint_dev_reg = 'https://7819830ww6.execute-api.us-east-1.amazonaws.com/dev-reg'
const endpoint = endpointProd;



const urlModoProd = 'https://ocyab5g1hd.execute-api.us-east-1.amazonaws.com/prod'
const urlModoDev = 'https://p8tjfv9zoa.execute-api.us-east-1.amazonaws.com/dev'
const modoProd = true


export const getPrebooking = async (request) => {
  const headers = {
    'Content-Type': "application/json",
    'token': getClient()
    };

    //const response = dataInitSteps;
   const url = endpoint+'/initSteps'
   const response = await axios.post(url, JSON.stringify(request), {headers:headers})
    .then(res => {
      //console.log(res);
      return res.data;
    })
    .catch((err) => {
      //console.log(err);
      return false;
    })

  return response;
}

export const getPayment =  async  (request) => {

  const url = endpoint+'/initStepPayment';
  const headers ={
    'content-type': "application/json",
    'token': getClient()
  };
  const response =  await axios.post(url,JSON.stringify(request),{headers:headers})
  .then(res => {
    //console.log(res);
    return res.data;
  })
  .catch((err) => {
    //console.log(err);
    return false;
  }); 

  return response;
  
}

export const getPaymentIntentionModo = async (data) => {
   var urlModoBase = urlModoDev;
   if (modoProd){
     urlModoBase = urlModoProd;
   }
  //const res = await fetch('https://ocyab5g1hd.execute-api.us-east-1.amazonaws.com/prod/v1/modo-checkout', {
  const res = await fetch(urlModoBase+'/v1/modo-checkout', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': 'Bearer crisModo.travel.test'
    },
    body: JSON.stringify(data)
  });

  const jsonRes = await res.json();

  console.log('%c getPaymentIntentionModo / jsonRes :', 'color: #b37900; background: #fff7e6', jsonRes);

  return {
    checkoutId: jsonRes.data.id,
    qrString: jsonRes.data.qr,
    deeplink: jsonRes.data.deeplink,
  };
}

export const getConfirmation = async (request) => {
  const headers ={
    'Content-Type': "application/json",
    "token": getClient()

  };

  const url = endpoint+'/initStepConfirmation';
  const response = await axios.post(url, JSON.stringify(request),{headers:headers})
    .then(res => {
      //console.log(res);
      return res.data;
    })
    .catch((err) => {
      //console.log(err);
      return false;
    });

  return response;
}

export const getBooking = async (request) => {
  const headers ={
    'Content-Type': "application/json",
    "token": getClient()

  };

   	//const url = 'https://1icm5bl099.execute-api.us-east-1.amazonaws.com/stg/lastStep';
    //const url = 'https://d2enb6p7uwvvfo.cloudfront.net/stg/lastStep';
    const url = 'https://apigw-api-checkout.api-services-group.com/api-checkout/prod-bna/lastStep';
    ///const url = endpoint+'/lastStep';
   	const response = await axios.post(url, JSON.stringify(request),{headers:headers})
   	.then(res => {
     	//console.log(res);
     	return res.data;
   	})
   	.catch((err) => {
     	//console.log(err);
     	return false;
   	});

  	return response;
}

export const getDataModoValid = (data) => {
    console.log(data.module)
    console.log(data.dataStep.SHOW_INFO.response.step.data.date)
    var modulo = 'NA';

    if (data.module){
        modulo = data.module;
    }
    let {checkin = 'NA', checkout = 'NA'} = data.dataStep.SHOW_INFO.response.step.data.date;

    if (data.module === "flights"){
        modulo = data.products.pivot.map(val => {
            if( val.cardDetail.validateCarrier){
                return val.cardDetail.validateCarrier;
            }
            return 'NA';
        })[0];
    }
   return modulo+';'+checkin+';'+checkout;
}