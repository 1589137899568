import React, { Component } from 'react'
import {Typography, Grid} from '@material-ui/core';


class TotalPrice extends Component {
    /* constructor(props) {
        super(props)
        console.log('TotalPrice:', props)
    } */

    render() {
        const {installment, purchaseDetail} = this.props
        
        const GetTotalPrice = (props) => {
            return(
                <div className="total_outlined">
                    <Grid container>
                        <Grid item xs={6}><Typography>Total</Typography></Grid>
                        <Grid item xs={6}>
                            <Typography className="text-right amount">
                                <span>{purchaseDetail.currency}</span> 
                                {/* {Object.keys(installment).length !== 0 ? (installment.total_amount) : (purchaseDetail.totalAmount)} */}
                                {Object.keys(installment).length !== 0 ? (installment.purchaseDetail.totalAmount) : (purchaseDetail.totalAmount)}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            )
        }

        return ( <GetTotalPrice /> )
    }
}

export default TotalPrice