import React  from 'react'
import {Typography } from '@material-ui/core';
import IconC from '../_IconC'

export default function CardBrandedFares(props) {
    const {template, item} = props   

    return (
        <div className={template}>
            {item.details.detailProduct && 
            <ul className="detail_item_list products products_cardBrandedFares">    
                {item.details.detailProduct.map((item, i) => 
                    <li key={i} className={`${item.className} list_item`}>
                        <Typography component="p" className={item.icon ? 'has_icon' : ''}>
                            {item.icon && <IconC icon={item.icon} className="custom_icon" />}  
                            {item.title && <span className="title">{item.title}</span> } {" "}
                            {item.description && <span className="description">{item.description}</span>}
                        </Typography>
                    </li>
                )} 
            </ul>}            
        </div>
    )
}
