import React from 'react'
import NumberFormat from 'react-number-format';

export default function Quotes(props) {

    console.log('common/Quotes props', props)

    const { length, item: {coef, cardName, payment_fees, unify_coef, bonificacion, totalAmount, 
        installment_quota_initial_value, installment_quota_initial_cantidad, installment_quota_final_value,
        installment_quota_final_cantidad , cardType, statusPayment }, currency } = props

    console.log('statusPayment', statusPayment)

    //const total  = <NumberFormat prefix={`${currency}`} value={Number(totalAmount)} decimalSeparator="," displayType="text" type="number" thousandSeparator="." allowNegative={false} />
    const total  = totalAmount
    
    const initial_value  = <NumberFormat prefix={`${currency}`} value={Number(installment_quota_initial_value)} decimalSeparator="," displayType="text" type="number" thousandSeparator="." allowNegative={false} />
    const final_value  = <NumberFormat prefix={`${currency}`} value={Number(installment_quota_final_value)} decimalSeparator="," displayType="text" type="number" thousandSeparator="." allowNegative={false} />

    const interest = coef === '1.0000' ? ( <span className='text-offer'>sin interés</span> ) : ( <span>fijas</span> );

    const quota_initial = installment_quota_initial_cantidad;
    let quota_final = installment_quota_final_cantidad;
    if ( quota_final > 0 && quota_initial >= 1){
          quota_final += 1;
    }
    console.log('quota_final', quota_final);

    const quotes = payment_fees === 1 ? ( <>
         {/* Ej. 1 pago sin interés */}
         {statusPayment !== 'undefined' && statusPayment === false ? ( <>
            <span className='option-cuotas-info saldo-pendiente'>Saldo pendiente: </span>
        </>) : (<>
            <span className='option-cuotas-info'>{payment_fees} pago{' '}
            {cardType !== 'debit_card' && <span className='text-offer'>sin interés{' '}</span>}({cardName})</span>
        </> )}
        
        </>
       
        ) : unify_coef ? ( <>
        {/* Ej. 6 cuotas sin interés de $xxx */}
            <span className='option-cuotas-info'>{payment_fees} cuotas{' '}
            <span className='text-offer'>sin interés</span> de {initial_value} ({cardName})</span> 
            </>
        ) : ( <> 
        {/* Ej. 6 cuotas sin interés
            1 pago de $xxx y 6 cuotas sin interes de $xxx */}
            <span className='option-cuotas-info'>
                {quota_initial > 0 && <>	
                    {quota_initial} {quota_initial > 1 ? <>cuotas {interest}</> : <>pago </>} de {initial_value}</>}
                {quota_final > 0 && <>{' '}y {quota_final} cuotas {interest} de {final_value}</>} ({cardName})
            </span>									
        </> );	

  return (<>
    <span className='quotes-selected'>{quotes}</span>

    {bonificacion.amount > 0 && ( <span className='bonificacion'>{' '} Bonificación {currency} {bonificacion.amount}.</span> )} 

    {statusPayment !== 'undefined' && statusPayment === false ? (
        <span className='total-a-pagar'>{' '}{currency} {total}</span>
    ) : (
        <span className='total-a-pagar'>{' '}{length > 0 ? 'Subtotal' : 'Total'} {currency} {total}</span>
    )}    

  </>
    
  )
}
