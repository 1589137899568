import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import {
	Card,
	CardHeader,
	CardContent,
	Typography,
	Avatar,
	Icon,
	Grid,
	Badge,
	Hidden,
} from '@material-ui/core';

import { setPurchaseAction } from "../actions/StepActionV2";
import DetailDialog from './layout/purchase/DetailDialogV2';
import InfoPopover from './layout/InfoPopover';
import Quotes from './common/Quotes'

//const priceDetailProduct = [{"addToCart":true,"title":"VUELO EZE-MIA","amount":"617.279","amountReal":617278.59,"component":"purchdet.pdet","charge":[{"title":"3 Adulto(s)","valor":"262.449","valorReal":262449},{"title":"1 Ni\u00f1o(s)","valor":"52.538","valorReal":52538},{"title":"Imp. y Tasas","valor":"90.287","valorReal":90286.7},{"title":"Impuesto PAIS","valor":"94.496","valorReal":94496.1},{"title":"Percepcion Afip RG 4815...2020","valor":"110.246","valorReal":110245.6},{"title":"Cargos","valor":"7.263","valorReal":7263.19},{"title":"Precio por persona","valor":"154.320","valorReal":154319.65}],"currency":"ARS","priceOffer":"","promoLabel":""}]
//const priceDetailCards = [{"installmentAmount":"1 pago(s) de $12.476,46","totalAmount":"5.040","price":[{"title":"Costo Financiero","addToCart":false,"currency":"ARS","charge":[],"component":"","priceOffer":"","promoLabel":"","amount":"2.520","amountReal":2519.71}]}]
//const purchaseDetailNew = {"title":"Detalle de tu compra","subtitle":"Sab 04-06 al Dom 12-06 - 1 persona(s)","totalLabel":"Total","currency":"$","totalAmount":"221.504","totalAmountReal":221503.8,"icon":"shopping_cart","template":"offer","priceDetailProduct":[{"addToCart":true,"title":"VUELO EZE-MIA","amount":"232.579","amountReal":232578.99,"icon":"info_outline","template":null,"charge":[{"title":"1 Adulto(s)","valor":"118.822","valorReal":118822},{"title":"Imp. y Tasas","valor":"25.448","valorReal":25447.5},{"title":"Impuesto PAIS","valor":"35.647","valorReal":35646.6},{"title":"Percepcion Afip RG 4815/2020","valor":"41.588","valorReal":41587.7},{"title":"Cargos","valor":"11.075","valorReal":11075.19},{"title":"Costo de transaccion","valor":22150.38,"valorReal":22150.38}],"currency":"ARS","priceOffer":"","promoLabel":""}],"priceDetailCards":[{"installmentAmount":"1 pago(s) de $221.503,80","totalAmount":"221.504","price":[{"title":"Costo Financiero","addToCart":false,"currency":"ARS","charge":[],"component":"","priceOffer":"","promoLabel":"","amount":"3.124","amountReal":3124.31}]},{"installmentAmount":"18 pago(s) de $788,86","totalAmount":"14.200","price":[]}]}


class PurchaseDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			purchaseDetail: '',
		};
	}

	render() {
		console.log("this.props purchaseDetail",  this.props)
		console.log("this.state purchaseDetail",  this.state)
		const purchaseDetail =(this.props.stepReducerV2.purchase!==null && Object.keys(this.props.stepReducerV2.purchase).length > 0) ? this.props.stepReducerV2.purchase : this.props.purchaseDetail;
		//const purchaseDetail = purchaseDetailNew
		if (this.props.stepReducerV2.purchase == null){
			console.log("Detecta purchase en null, lo seteara por primera vez", purchaseDetail);
			this.props.setPurchaseAction(purchaseDetail);
		}


		let newAddToCart = {}
		newAddToCart["priceDetailProduct"] =  this.props.purchaseDetail.priceDetail.filter(
			(element) => element.addToCart === true
		);
		newAddToCart["priceDetailCards"] =  []  

		const addToCart = (this.props.stepReducerV2.addToCart!==null && Object.keys(this.props.stepReducerV2.addToCart).length > 0) ? this.props.stepReducerV2.addToCart : newAddToCart;
		const products = this.props.products;
		const selectedData = this.props.selectedData;
		const installment = this.props.installment;
		const badge = purchaseDetail.priceDetail.filter(
			(element) => element.addToCart === true
		);

		//const badge = purchaseDetail.priceDetailProduct

		console.log('purchaseDetail', purchaseDetail)
		
		let priceDetailProduct = null;
		let priceDetailCards = null;
		if (addToCart.priceDetailProduct!= undefined && addToCart.priceDetailCards!= undefined){
			console.log("addToCart en	 purchaseDetailV2", addToCart)
			priceDetailProduct = addToCart.priceDetailProduct;
			priceDetailCards = addToCart.priceDetailCards;
		}else{ // setea valor por defecto para que no de error.
			priceDetailProduct = [];	
			priceDetailCards = [];
		}

		let purchaseReducer = (this.props.stepReducerV2.purchase !== null) ? this.props.stepReducerV2.purchase : purchaseDetail;

		return (
			<div className='purchase_detail'>
				<Card className='purchase_card card_primary_color'>
					<CardHeader
						avatar={
							<Avatar
								aria-label={purchaseDetail.title}
								className='avatar_purchase_detail'
							>
								<Badge
									badgeContent={badge.length}
									color='primary'
									overlap='circular'
								>
									<Icon>{purchaseDetail.icon}</Icon>
								</Badge>
							</Avatar>
						}
						/* Open Modal Info */
						action={
							<DetailDialog
								installment={installment}
								selectedData={selectedData}
								icon='expand_more'
								purchaseDetail={purchaseDetail}
								products={products}
								badgeContent={badge.length}
								priceDetailProduct={priceDetailProduct}
								priceDetailCards={priceDetailCards}
							/>
						}
						/* Modal */
						title={
							<Typography className='title_card_header' variant='h3'>
								{purchaseDetail.title}
							</Typography>
						}
						subheader={
							<Typography noWrap className='title_card_subheader' variant='body2' >
								{purchaseDetail.subtitle}
							</Typography>
						}
					/>

					<Hidden smDown>
						<CardContent>
							{/*priceDetailProduct muestra los items que el usuario está comprando
								- Vuelo, Asistencia, tarifa.
							*/}
							{priceDetailProduct.map((item, i) => {
								return (
									<Fragment key={i}>
										<Grid
											container
											className={`item_resume ${(item.className =
												'')} template-${item.template}`}
										>
											<Grid item xs={8}>
												<div className='container_item_resume'>
													<Typography variant='body2'>
														<span>{item.title}</span>
														{item.charge.length > 0 && (
															<InfoPopover
																className='info_charge'
																charge={item.charge}
																title='Cargos'
																icon={item.icon}
																currency={purchaseDetail.currency}
															/>
														)}
													</Typography>
												</div>
											</Grid>
											<Grid item xs={4}>
												<div className={`text-right`}>
													<span>
														{purchaseDetail.currency} {item.amount}
													</span>
												</div>
											</Grid>
										</Grid>
									</Fragment>
								)
							})}


							{/*priceDetailCards muestra el detalle del total con respecto a como se está pagando
								-con una o dos tarjetas, de que marca, etc.
							*/}
							{priceDetailCards.map((item, i) => {
								console.log('item priceDetailCards', item)
								const cardName = item.cardName
								const indexCard= item.indexCard+1
								return (
									<>
									{/* { item.price.length>0 &&
										<Typography variant='body2'>Tarjeta {item.indexCard+1}</Typography>
									} */}
									{item.price.map((item, i) => {
										return (
											<Fragment key={i}>
												<Grid
													container
													className={`item_resume ${(item.className =
														'')} template-${item.template}`}
												>
													<Grid item xs={9}>
														<div className='container_item_resume'>
															<Typography variant='body2'>
																<span>{item.title} <span style={{fontSize: '80%'}}>(tarjeta {indexCard} - {cardName})</span></span>
																{/* {item.charge.length > 0 && (
																	<InfoPopover
																		className='info_charge'
																		charge={item.charge}
																		title='Cargos'
																		icon={item.icon}
																		currency={purchaseDetail.currency}
																	/>
																)} */}
															</Typography>
														</div>
													</Grid>
													<Grid item xs={3}>
														<div className={`text-right`}>
															<span>
																{purchaseDetail.currency} {item.amount}
															</span>
														</div>
													</Grid>
												</Grid>
											</Fragment>
										)
									})}
									</>
									
								)
							})}

						</CardContent>
					</Hidden>
					<div className='card_footer'>
						<Grid container className='card_footer-total'>
							<Grid item xs={7}>
								<Typography className='uppercase strong'>
									{purchaseReducer.totalLabel}
								</Typography>
								
								
							</Grid>
							<Grid item xs={5}>
								<Typography className='text-right amount'>
									<span>{purchaseReducer.currency}</span>{' '}
									{ purchaseReducer.totalAmount}
								</Typography>
							</Grid>
						</Grid>

						

						{priceDetailCards.map((item, i) => {
							console.log('priceDetailCards item', item)

							{/* <Typography variant='body2' style={{fontSize: 12}}>
										{`${item.installmentAmount} ${item.cardName}`}
									</Typography> */}
								return (
									<Typography className='card_footer-quotes' key={i} variant='body2'>
										<Quotes length={priceDetailCards.length} item={item} currency={purchaseReducer.currency} />
									</Typography>
										
								)
							})}

						{Object.keys(installment).length !== 0 && (
							<Hidden smDown>
								<Grid container>
									<Grid item xs={12}>
										<Typography className='installment-amount'>
											<span>
												{installment.purchaseDetail.installmentAmount}
											</span>
										</Typography>
									</Grid>
								</Grid>
							</Hidden>
						)}
					</div>
				</Card>
			</div>
		);
	}
}


const mapStateToProps = reducers => {
    return reducers;
};

const mapDispatchToProps = dispatch => {
    return {
        setPurchaseAction: (newPurchase) =>
            dispatch(
                setPurchaseAction(newPurchase)
            )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {forwardRef:true}
)(PurchaseDetail);
