
import React, { Component, Suspense,Fragment } from 'react'
import PreloadSection from "./layout/preloads/PreloadSection";
import PreloadBooking from "./layout/preloads/PreloadBooking";
import { connect } from "react-redux";
import { retrieveAction } from "../actions/LayoutActionV2";
import Header from "./Header";
import FooterFinalView from "./FooterFinalView";
import Theme from "../assets/Theme";
import config from "../config/config.json";
//import getRetried from "../demo-request/flights_v2/getRetried105.json";
//import getRetried from "../demo-request/flights_v2/getRetried104.json";
//import getRetried from "../demo-request/flights_v2/getRetried103.json";
//import getRetried from "../demo-request/flights_v2/getRetried102.json";
//import getRetried from "../demo-request/flights_v2/getRetried101.json";
//import getRetried from "../demo-request/flights_v2/getRetried101Error102.json";
//import getRetried from "../demo-request/flights_v2/getRetried101Error103.json";
//import getRetried from "../demo-request/flights_v2/getRetried101Error104.json";
//import getRetried from "../demo-request/flights_v2/getRetried101Error105.json";
//import getRetried from "../demo-request/flights_v2/getRetried102Error103.json";
//import getRetried from "../demo-request/flights_v2/getRetried102Error104.json";
//import getRetried from "../demo-request/flights_v2/getRetried102Error105.json";
//import getRetried from "../demo-request/flights_v2/getRetried103Error104.json";
//import getRetried from "../demo-request/flights_v2/getRetried103Error105.json";
//import getRetried from "../demo-request/flights_v2/getRetriedConfirmed.json";
//import getRetried from "../demo-request/flights_v2/getRetriedConfirmedError101.json";
//import getRetried from "../demo-request/flights_v2/getRetriedConfirmedError102.json";
//import getRetried from "../demo-request/flights_v2/getRetriedConfirmedError103.json";
//import getRetried from "../demo-request/flights_v2/getRetriedConfirmedError104.json";
import getRetried from "../demo-request/flights_v2/getRetriedConfirmedError105.json";

import Metas from "./Metas";
import PreloadV2 from "./layout/preloads/PreloadV2";

class ThankYouPageV2 extends Component {
    constructor(props) {
        super(props)
        console.log(props)
        this.state = {
            transactionCompleted : false
        }
        this.intervalRetrieve = null;
        this.secondsToWait = 5;
    } 
    
    componentDidMount(){
        console.log("Inicializando intervalo de retrieve")
        this.intervalRetrieve = window.setInterval(this.getRetrieve, this.secondsToWait*1000);
        console.log("intervalId ", this.intervalRetrieve)
    }

    componentDidUpdate(prevProps, prevState){
        // Si llegó una nueva respuesta de retrieve
        if (prevProps.responseRetrieve != this.props.responseRetrieve){
            if (this.props.responseRetrieve!==null){

                if (this.props.responseRetrieve.completed !== undefined && this.props.responseRetrieve.completed){
                    // Se completaron todos los procesos.
                    console.log("Redireccionando a vista final.")
                    // Cancela intervalos
                    clearInterval(this.intervalRetrieve)
                    this.intervalRetrieve = null

                }
            }else{
                console.log("Todavia no se completaron todos los procesos.")
                if (this.intervalRetrieve==null){
                    console.log("Se volvio a inicializar el intervalo.");
                    this.intervalRetrieve = window.setInterval(this.getRetrieve, this.secondsToWait*1000);
                }
            }
        }
    }

    getRetrieve = () => {	
        console.log("Ejecuta funcion intervalo")
        console.log("location thanyoupage",this.props.location.state);
        if (this.intervalRetrieve!==null){
            let requestDefault=  {
                // "hash": "5b887b9c-9a6c-11ec-888a-42237af2d8bc", // TEST
                "hash": (this.props?.location?.state?.dataFinal?.data!= undefined) ? this.props.location?.state?.dataFinal?.data.hash : this.props?.dataFinal?.data.hash,
                // "module":  "flights",   // TEST
                "module":  (this.props?.location?.state?.dataFinal?.data!= undefined) ? this.props?.location?.state?.dataFinal?.data.product : this.props?.match?.params.module,
                "steps": {
                "payment": {
                    "status": false,
                    "data": []
                },
                "booking": {
                    "status": false,
                    "data": []
                },
                "crossel": {
                    "status": false,
                    "data": []
                }
                }
            }

            /*let request;
            
            if (this.props.responseRetrieve==null || this.props.responseRetrieve.error!=undefined){
                request = requestDefault;
                console.log("Enviara al retrieve una request default")
            }else{
                requestDefault["steps"] = this.props.responseRetrieve.data
                request = requestDefault;
                console.log("Enviara al retrieve una request con data de respuesta anterior")
            }*/

            this.props.retrieveAction(requestDefault);
        }
    }


    getStatusTemplate() { 
        console.log("datos", this.props.responseRetrieve.response)
        console.log("Cargando template final...")
        //let ip = "192.168.0.1"; // TEST
        let ip = this.props?.location?.state?.ip ? this.props?.location?.state?.ip : this.props.ip;
        //let prebooking = {}; // TEST
        let prebooking = this.props?.location?.state?.prebooking ? this.props?.location?.state?.prebooking : this.props.prebooking;
        let retrieveBooking =  this.props.responseRetrieve.response;
        let containerModule = retrieveBooking.step.nameContainer;

        let module = (this.props.responseRetrieve.response.module!= undefined) ? this.props.responseRetrieve.response.module : this.props.match.params.module
        let folderCountry  = containerModule.charAt(0).toUpperCase() + containerModule.slice(1);
        let foldersPath = folderCountry.replaceAll("_", "/")         
        let Component = React.lazy(() => import(`./layout/thankYouPage/${foldersPath}`));
        return (<section className={`thankyoupage ${this.props.responseRetrieve.response.step.nameContainer}`}>
                <Metas client={config.client} module={module.toLowerCase()} /> 
                <Suspense fallback={<PreloadSection/>}>
                    <Component 
                        dataFinal={retrieveBooking} 
                        prebooking={prebooking} 
                        client={config.client}  
                        styles={config.styles}        
                        ip={ip}   
                    />
                </Suspense>
            </section>
        )
    }

    render() {
        console.log("thanyoupage props", this.props);
        //let module= "flights" //TEST
        //let module = this.props.dataFinal.module

        let userLogin = this.props.location.state && this.props.location.state.userLogin ? this.props.location.state.userLogin : '';

        const stylesConfig = config.styles
        return (
            <Fragment>
                
                           

                <Theme>               
                <div className="wrapper" styles={stylesConfig}>
                    <Header client={config.client} userLogin={userLogin}/>
                    <div className="main">

                        { (!this.props.transactionCompleted)
                            ? 
                                <PreloadV2 />
                            :
                                /*  { (this.props.location.state.preload) ? (
                                    <PreloadBooking/>
                                ) : ( */
                                this.getStatusTemplate()
                                /*)} */
                        } 
                    </div>
                    <FooterFinalView client={config.client}/>
                </div>
                </Theme>
            </Fragment>
        )
    }
}

const mapStateToProps = reducers => {
    return reducers.layoutReducerV2;
};

const mapDispatchToProps = dispatch => {
    return {
        retrieveAction: (request) =>
            dispatch(
                retrieveAction(
                    request
                ),
            )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ThankYouPageV2);