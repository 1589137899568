import React from 'react'
import { Card, Icon, Container, CardContent, Avatar, Typography } from '@material-ui/core';

export default function PreloadV2() {
  return (
    <>
    <Container maxWidth="sm" >
        <Card className='preloader-v2'>
            <div className="card_header_preloader">
                <Avatar className='card_header_preloader_avatar' >
                    <Icon className='card_header_preloader_icon'>shopping_cart</Icon>
                </Avatar>
                <Typography variant={'h3'} className="preloader_v2_title">Estamos procesando tu compra</Typography>
                <div className="preloader-dots">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>
            </div>
            <CardContent>
                <Typography className='card_header_preloader_description' variant={'body2'}>¡Ya falta poco! <br /> Aguarda unos minutos mientras procesamos tu compra.</Typography>
            </CardContent>
        </Card>
    </Container>
    
    </>        
  )
}
