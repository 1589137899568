
import React, { Component, Suspense, Fragment } from 'react'
import { connect } from "react-redux";
import { getPrebookingAction, getBookingAction, updatePreloadLayoutAction } from "../actions/LayoutAction";
import { getInfoStepAction, updatePreloadAction } from "../actions/StepAction";
import Header from "./Header";
import FooterFinalView from "./FooterFinalView";
import Theme from "../assets/Theme";
import config from "../config/config.json";
import PreloadV2 from './layout/preloads/PreloadV2';
import PreloadSection from './layout/preloads/PreloadSection';

class ThankYouPageModo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            transactionCompleted: false
        }
        //this.intervalRetrieve = null;
        this.intervalRetrieve = false;
        this.secondsToWait = 5;
        this.responseReceived = true;
    }
    
    getStatusTemplate(containerModule, dataFinal,prebooking,ip) { 
        
        let folderCountry  = containerModule.charAt(0).toUpperCase() + containerModule.slice(1);
        let Component = React.lazy(() => import(`./layout/thankYouPage/${folderCountry}`));
        return (
        <Suspense fallback={<PreloadSection/>}>
            <Component 
                dataFinal={dataFinal} 
                prebooking={prebooking} 
                client={config.client}  
                styles={config.styles}        
                ip={ip}        
            />
        </Suspense>
        )
    }

    UNSAFE_componentWillMount() {
        const hash = this.props.match.params.hash;
        this.setState({ hash: hash,prebooking:this.props.prebooking });

        this.props.dispatchGetPrebookingAction(hash);
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('first CARS', this.props)
        // Si tiene IP (recibió data de initStep), sigue el proceso de Modo llamando a lastStep con la info de pago
        // La validación de responseReceived es para que no vuelva a pasar por el mismo lugar 2 veces, una vez que ya haya llegado la respuesta
        if(this.props.ip &&  this.responseReceived){
            this.getModo();
            this.responseReceived = false;
        }

        if (Object.keys(this.props.dataFinal).length !== 0  &&  this.responseReceived) {
            this.props.updatePreloadAction(false);
            this.setState({ "finalStepPreload": true })
            this.getStatusTemplate(this.props.dataFinal.step.nameContainer, this.props.dataFinal,this.props.prebooking, this.props.ip)
        }
    }

    getModo = () => {      
        const { match } = this.props;
        let hash = match.params.hash;
        let info = {
            "modo": true
        }
        //this.props.updatePreloadLayoutAction(true);
        this.props.dispatchGetBookingAction(this.props.ip, hash, null, info);
    }

    render() {
        let userLogin = this.props.location.state && this.props.location.state.userLogin ? this.props.location.state.userLogin : '';
        let dataFinal = '';
        let prebooking = '';
        let nameContainer = '';
        let ip = '';
        if(Object.keys(this.props.dataFinal).length != 0){

            dataFinal = this.props.dataFinal
            prebooking = this.props.prebooking
            nameContainer = this.props.dataFinal.step.nameContainer
            ip = this.props.ip
        }

        console.log('this.props.dataFinal', this.props.dataFinal)
        console.log('this.props.prebooking', this.props.prebooking)

        const stylesConfig = config.styles
        return (
            <Fragment>
                <Theme>
                    <div className="wrapper" styles={stylesConfig}>
                        <Header client={config.client} userLogin={userLogin} />
                       
                        { (Object.keys(this.props.dataFinal).length === 0) ? (
                            <PreloadV2/>
                            ) : (
                                <section className={`thankyoupage ${nameContainer}`}>
                                    {this.getStatusTemplate(nameContainer,dataFinal,prebooking,ip)}
                                </section>
                            )}
                        <FooterFinalView client={config.client} />
                    </div>
                </Theme>
            </Fragment>
        )
    }
}

const mapStateToProps = reducers => {
    return reducers.layoutReducer;
};

const mapDispatchToProps = dispatch => {
    return {
        dispatchGetPrebookingAction: (hash) =>
            dispatch(
                getPrebookingAction(
                    hash
                ),
            ),
            dispatchGetBookingAction: (ip,hash, dataStep, info) =>
            dispatch(
                getBookingAction(
                    ip,
                    hash,
                    dataStep,
                    info
                )
            ),
            updatePreloadLayoutAction: (boolean) =>{
                dispatch(updatePreloadLayoutAction(boolean))
            },
            dispatchGetInfoStepAction: (ip, hash, nextStepAction, addToCart, currentDataStep, lastDataStep) =>
            dispatch(
                getInfoStepAction(
                    ip, hash, nextStepAction, addToCart, currentDataStep, lastDataStep
                ),
            ),
            updatePreloadAction: (boolean) =>{
                dispatch(updatePreloadAction(boolean))
            }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ThankYouPageModo);