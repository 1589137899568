import React, { Component, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Layout from "./components/Layout";
import LayoutV2 from "./components/LayoutV2";
import LayoutError from "./components/LayoutError";
import ErrorViewHandler from "./components/ErrorViewHandler";
import ErrorViewHandlerV2 from "./components/ErrorViewHandlerV2";
import PreloadSection from "./components/layout/preloads/PreloadSection";
import LayoutRetry from "./components/LayoutRetry";
import ThankYouPage from "./components/ThankYouPage";
import ThankYouPageV2 from "./components/ThankYouPageV2";
import Test from "./components/LayoutTest";
import {getClientInicial} from "./services/Authentication";

import config from "./config/config.json";
import ThankYouPageModoV2 from "./components/ThankYouPageModoV2";
import ThankYouPageModo from "./components/ThankYouPageModo";
const token = getClientInicial()

const routings ={
    "layoutV2":{
        path:"/:module/prebooking/:hash",
        component: LayoutV2
    },
    "layout":{
        path:"/:module/prebooking/:hash",
        component: Layout
    },
    "thankYouPageV2":{
        path:"/checkout/:module/booking/id/:idTransaction",
        component: ThankYouPageV2
    },
    "thankYouPage":{
        path:"/checkout/:module/booking/id/:idTransaction",
        component: ThankYouPage
    },
    "thankYouPageModoV2": {
        path: "/checkout/:module/booking/modo/:hash",
        component: ThankYouPageModoV2
    },
    "thankYouPageModo": {
        path: "/checkout/:module/booking/modo/:hash",
        component: ThankYouPageModo
    },
    "errorViewHandlerV2":{
        path:"/checkout/error/disponibilidad",
        componentName: "ErrorViewHandlerV2",
        reducer: "layoutReducerV2"
    },
    "errorViewHandler":{
        path:"/checkout/error/disponibilidad",
        componentName: "ErrorViewHandler",
        reducer: "layoutReducer"
    }
}

class Routing extends Component {
    getErrorHandler(componentName, pathConfig) { 
        console.log("Cargando template error...", componentName)
        let Component = React.lazy(() => import(`./components/${componentName}`));
        return (
            <Suspense fallback={<PreloadSection/>}>
                <Component pathConfig={pathConfig}/>
             </Suspense>
        )
    }

    render() {

        console.log(token)
        const templates = config.client.tokens[token].templates
        console.log(routings)
        console.log(config)
        return (
            <BrowserRouter>
                <Switch>
                   
                    <Route path={routings[templates.layout].path} component={routings[templates.layout].component} exact />
                   
                    <Route path={"/retried/:hash"} component={LayoutRetry} />

                    <Route path={routings[templates.thankYouPageModo].path} component={routings[templates.thankYouPageModo].component} />

                    <Route path={routings[templates.thankYouPage].path} component={routings[templates.thankYouPage].component} />
                   
                    <Route path={config.defaultErrorRoute} component={LayoutError} exact />
                    {
                        config.routesError.map((routeError, i) =>
                            <Route path={routeError.path} exact key={i} >
                                {this.getErrorHandler( routings[templates.errorViewHandler].componentName, routeError.path)}
                            </Route>
                        )
                    } 

                    <Route path={"/test"} component={Test}  />
                    {console.log("!!!!!!!!!!!!! Routing Test new url")}
                </Switch>
            </BrowserRouter>
        );
    }
}

export default Routing;