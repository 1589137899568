
import React, { Component, Fragment } from 'react'
import { connect } from "react-redux";
import { getPrebookingAction, getBookingAction, updatePreloadLayoutAction } from "../actions/LayoutActionV2";
import { getInfoStepAction, updatePreloadAction } from "../actions/StepActionV2";
import Header from "./Header";
import FooterFinalView from "./FooterFinalView";
import Theme from "../assets/Theme";
import config from "../config/config.json";
import PreloadV2 from "./layout/preloads/PreloadV2";

class ThankYouPageModoV2 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            transactionCompleted: false
        }
        //this.intervalRetrieve = null;
        this.intervalRetrieve = false;
        this.secondsToWait = 5;
        this.responseReceived = true;
    }


    componentDidMount() {
        const hash = this.props.match.params.hash;
        // Actualiza state
        this.setState({ hash: hash, prebooking:this.props.prebooking });
        this.props.dispatchGetPrebookingAction(hash);
    } 

    componentDidUpdate(prevProps, prevState) {
        // Si tiene IP (recibió data de initStep), sigue el proceso de Modo llamando a lastStep con la info de pago
        // La validación de responseReceived es para que no vuelva a pasar por el mismo lugar 2 veces, una vez que ya haya llegado la respuesta
        if(this.props.ip &&  this.responseReceived){
            this.getModo();
            this.responseReceived = false;
        }

        const module = this.props.match.params.module
        if (Object.keys(this.props.dataFinal).length !== 0) {
            const idTransaction = this.props.dataFinal.data.idTransaction
            this.props.updatePreloadAction(false);
            this.setState({ "finalStepPreload": true })
            this.props.history.push(`/checkout/${module}/booking/id/${idTransaction}`)
        }
    }


    getModo = () => {      
        const { match } = this.props;
        let hash = match.params.hash;
        let module = match.params.module;
        // console.log("queryParamsObject",queryParamsObject);
    
        let info = {
            "modo": true
        }
        //this.props.updatePreloadLayoutAction(true);
        this.props.dispatchGetBookingAction(this.props.ip, hash, null, null, info);
    }
    
    render() {
        let userLogin = this.props.location.state && this.props.location.state.userLogin ? this.props.location.state.userLogin : '';
        console.log('first', this.props)

        console.log('this.props.dataFinal', this.props.dataFinal)
        console.log('this.props.prebooking', this.props.prebooking)

        const stylesConfig = config.styles
        return (
            <Fragment>
                <Theme>
                    <div className="wrapper" styles={stylesConfig}>
                        <Header client={config.client} userLogin={userLogin} />
                       
                        <PreloadV2 />
                        
                        <FooterFinalView client={config.client} />
                    </div>
                </Theme>
            </Fragment>
        )
    }
}

const mapStateToProps = reducers => {
    return reducers.layoutReducerV2;
};

const mapDispatchToProps = dispatch => {
    return {
        dispatchGetPrebookingAction: (hash) =>
            dispatch(
                getPrebookingAction(
                    hash
                ),
            ),
        dispatchGetBookingAction: (ip,hash, dataStep,prevStep, info) =>
            dispatch(
                getBookingAction(
                    ip,
                    hash,
                    dataStep,
                    prevStep,
                    info
                )
            ),
            updatePreloadLayoutAction: (boolean) =>{
                dispatch(updatePreloadLayoutAction(boolean))
            },
            dispatchGetInfoStepAction: (ip, hash, nextStepAction, addToCart) =>
            dispatch(
                getInfoStepAction(
                    ip, hash, nextStepAction, addToCart
                ),
            ),
            updatePreloadAction: (boolean) =>{
                dispatch(updatePreloadAction(boolean))
            }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ThankYouPageModoV2);