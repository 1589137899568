import React, { Component } from 'react'
import {Typography, Grid,  Hidden} from '@material-ui/core';
import Image from './../../Image'

class PaymentSelected extends Component {
    /* constructor(props) {
        super(props)
        console.log('PaymentSelected:', props)
    } */

    render() {
        const {installment, purchaseDetail, classNameContainer = "" } = this.props
        
        const GetPaymentSelected = (props) => {
            
            return(
                <div className={classNameContainer}>
                    <div className="payment_selected">
                        <Typography component="p" variant="body1" className="mb-05"><strong>Forma de pago:</strong></Typography>
                        <Grid container spacing={1} >
                            <Grid item xs={12} md={9}>
                                <Typography component="p" variant="body2">Elegiste pagar con {installment.credit_card_name} {installment.bank_name}</Typography>
                                
                                {Object.keys(installment).length !== 0 && (
                                    <Typography variant="body2">
                                        {purchaseDetail.currency} {installment.total_amount} - ({installment.installment_amount})
                                    </Typography>
                                )}
                                
                            </Grid>
                            <Hidden smDown>
                                <Grid item xs={3} className="text-right">
                                    <Image className="bank_image_selected" img={`bancos/${installment.bank_code}.png`} alt={installment.bank_name} />
                                </Grid>
                            </Hidden>
                        </Grid>
                        
                    </div>
                </div>
                )
        }

        return ( <GetPaymentSelected /> )
    }
}

export default PaymentSelected