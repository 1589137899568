import React from 'react';
import config from "../config/config.json";

export const getClientInicial = () => {
  let pathArray = window.location.pathname.substr(1).split('/'); 
  console.log("pathArray",pathArray)
  // En cualquier pantalla de los steps del checkout el modulo está al comienzo de la URL. (Ejemplo: /fligths/prebooking/[hash])
  let module = pathArray[0]; 
  let token = null;
  
  if (pathArray[0]!==""){
    if (pathArray[0]=="checkout"){
      // En pantalla final de la reserva el modulo está segundo en la URL. (Ejemplo: /checkout/flights/booking/id/VL-111111)
      module = pathArray[1];  
    }
    
    console.log("modulo", module);
    window.localStorage.setItem('module',module);
    token = getToken(module);
  
  }else{
    // Si el path es vacío, no podemos saber el modulo todavía.
    // tomamos el token por defecto.
    token = config.client.token
  }
  return token;
}

export const getClient = () => {
 
  let module = window.localStorage.getItem('module');
  console.log("modulo2", module);
  let token = getToken(module)
  return token;
  
}


function getToken(module){
  
  if (config.client.modules[module]!=undefined){
	
    if (config.client.modules[module].token != undefined) {
	   console.log("Establece token de config según modulo especificado.")
      return config.client.modules[module].token
    }
  }
  console.log("No se encontró la config del modulo especificado. Establece token por defecto.")
  return config.client.token
 /*  if (config.client.modules[module]!=undefined){
    if (config.client.modules[module].token != undefined) {
        console.log("Establece token de config según modulo especificado.")
        token = config.client.modules[module].token      // token configurado para ese modulo    
    }else{
        console.log("El modulo especificado no tiene token propio. Establece token por defecto.")
        token = config.client.token                     // token por defecto
    }
  }else{
      console.log("No se encontró la config del modulo especificado. Establece token por defecto.")
      token = config.client.token
  }
  console.log("token", token);
  return token; */
}