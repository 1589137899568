import React, {Fragment} from 'react'
import {Grid, Typography, Icon} from '@material-ui/core';

export default function CardStatusTransaction(props) {
    const {idTransaction, content } = props
    return (
        <Fragment>
            {content.show_idTransaction &&
                <Grid container className="row_booking_number mb-1">
                    <Grid item md={6}><Typography className="text-left">
                        <strong>Tu número de pedido es:</strong></Typography></Grid>
                    <Grid item md={6}><Typography className="booking_number">
                        {idTransaction}
                    </Typography></Grid>                            
                </Grid> 
            }
            
            {content.show_status_booking && 
                <Grid container className="row_status_booking mb-1">
                    <Grid item xs={6}><Typography className="text-left">
                        <strong>Estado de reserva:</strong></Typography></Grid>
                    <Grid item xs={6}>
                        <Typography className="status_booking">
                            <Icon className="status_booking_icon">{content.status_booking_icon}</Icon> <span>
                                {content.status_booking}</span>
                        </Typography></Grid>                            
                </Grid>
            }
            
            {content.show_status_payment &&
                <Grid container className="row_status_payment mb-2">
                    <Grid item xs={6}><Typography className="text-left"><strong>
                        Estado de pago:</strong></Typography></Grid>
                    <Grid item xs={6}>
                        <Typography className="status_payment">
                            <Icon className="status_payment_icon">
                                {content.status_payment_icon}</Icon> <span>{content.status_payment}</span>
                        </Typography>
                    </Grid>                            
                </Grid> 
            }            
            
        </Fragment>
    )
}
