import React from 'react';
import {Icon} from '@material-ui/core';

import IconMdi from '@mdi/react'

import IconC from './_IconC'


import { 
    mdiHospitalBoxOutline, 
    mdiChevronRight, 
    mdiCreditCardOutline, 
    mdiFileDocument,
    mdiClipboardCheck, 
    mdiNoteText, 
    mdiAccountMultiple,
    mdiCurrencyUsdCircleOutline,
    mdiCreditCardMultipleOutline,
    mdiChatQuestion
  } from '@mdi/js'

export const GetIconMdi = (props) => {
    return <IconMdi className={props.className} path={props.path} title={props.title} size={props.size} color={props.color} />
}

export const IconAsistencia = (props) => {
    
    return <IconMdi className={props.className} path={mdiHospitalBoxOutline } title={props.title} size={props.size} color={props.color} />
}

export const IconChevronRight = (props) => {
    return <IconMdi className={props.className} path={mdiChevronRight } title={props.title} size={props.size} color={props.color} />
}

export const IconCreditCardOutline = (props) => {
    return <IconMdi className={props.className} path={mdiCreditCardOutline } title={props.title} size={props.size} color={props.color} />
}

export const IconBilling = (props) => {
    return <IconMdi className={props.className} path={mdiFileDocument } title={props.title} size={props.size} color={props.color} />
}

export const IconContact  = (props) => {
    return <IconMdi className={props.className} path={mdiChatQuestion } title={props.title} size={props.size} color={props.color} />
}

export const IconConfirmation = (props) => {
    return <IconMdi className={props.className} path={mdiClipboardCheck } title={props.title} size={props.size} color={props.color} />
}

export const IconPayment = (props) => {
    return <IconMdi className={props.className} path={mdiCurrencyUsdCircleOutline } title={props.title} size={props.size} color={props.color} />
}

export const IconNoteText = (props) => {
    return <IconMdi className={props.className} path={mdiNoteText } title={props.title} size={props.size} color={props.color} />
}

export const IconAccountMultiple = (props) => {
    return <IconMdi className={props.className} path={mdiAccountMultiple } title={props.title} size={props.size} color={props.color} />
}

export const IconPassengers = (props) => {
    return <IconMdi className={props.className} path={mdiAccountMultiple } title={props.title} size={props.size} color={props.color} />
}

export const IconCardDetail = (props) => {
    if (props.icon === 'flight') {
        return <Icon>flight</Icon>
    } else if (props.icon === 'assist') {
        return <Icon>local_hospital</Icon>
    } else if (props.icon === 'hotel') {
        return <Icon>hotel</Icon>
    } else if (props.icon === 'package') {
        return <Icon>work</Icon>
    } else if (props.icon === 'cars') {
        return <Icon>directions_car</Icon>
    } else {
        return <Icon>info</Icon>
    }
}

export const IconStep = (props) => {
    if (props.icon === 'crossell') {
        return <IconMdi className={props.className} path={mdiHospitalBoxOutline } title={props.title} size={props.size} color={props.color} />
    } else {
        return <Icon>info</Icon>
    }
}

export const IconPaymentMethod = (props) => {

    if (props.icon === 'tarjeta') {
        return <Icon>payment</Icon>
    } else if (props.icon === 'dos_tarjetas') {
        return <IconMdi className={props.className} path={mdiCreditCardMultipleOutline} title={props.title} size={props.size} color={props.color} />
    } else if (props.icon === 'pago_offline') {
        return <Icon>account_balance</Icon>
    } else if (props.icon === 'pago_modo') {
        return <IconC icon="modo" alt="Modo" className="custom_icon" />
    } else {
        return <Icon>info</Icon>
    }
}


