import React, { Component } from 'react'
import {Card, CardHeader, CardContent, Avatar, Typography, Icon} from '@material-ui/core';
import PaymentSelected from './PaymentSelected'
import TotalPrice from './TotalPrice'

class Sidebar extends Component {
    /* constructor(props) {
        super(props)
        console.log('Sidebar:', props)
    } */

    render() {
        const purchaseDetail = this.props.purchaseDetail
        const installment = this.props.payment[0].installment

        const GetSidebar = (props) => {
            return(
                <Card className="purchase_card card_primary_color">
                    <CardHeader
                        avatar={
                            <Avatar className="avatar_purchase_detail">
                                <Icon>{purchaseDetail.icon}</Icon>
                            </Avatar>
                        }
                        title={<Typography className="title_card_header" variant="h3">{purchaseDetail.title}</Typography>}
                    />
                    <CardContent>
                        <TotalPrice installment={installment} purchaseDetail={purchaseDetail} />
                    </CardContent>
                    
                    <PaymentSelected installment={installment} purchaseDetail={purchaseDetail} classNameContainer="card_footer" />                                    
                    
                </Card>
            )
        }

        return (
            <GetSidebar />
        )
    }
}

export default Sidebar